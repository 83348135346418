import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

const SESSION_ID_KEY = 'sessionId';
const LAST_ACTIVITY_KEY = 'lastActivity';
const TOKEN_KEY = 'accessToken';

export const localStorageUtils = {
  getSessionId: () => safeLocalStorage.getItem(SESSION_ID_KEY),
  getToken: () => safeLocalStorage.getItem(TOKEN_KEY),
  setSessionId: (sessionId: string) => safeLocalStorage.setItem(SESSION_ID_KEY, sessionId),
  getLastActivity: () => Number(safeLocalStorage.getItem(LAST_ACTIVITY_KEY)),
  setLastActivity: (timestamp: number) =>
    safeLocalStorage.setItem(LAST_ACTIVITY_KEY, String(timestamp)),
  clearAll: () => {
    safeLocalStorage.removeItem(SESSION_ID_KEY);
    safeLocalStorage.removeItem(LAST_ACTIVITY_KEY);
  }
};
