import { gql } from '@apollo/client';

export const registerDeviceMutation = gql`
  mutation RegisterDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      success
      message
      error {
        message
      }
      deviceId
    }
  }
`;
