import { useEffect, useRef, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { startSessionMutation } from './lib/api';
import { localStorageUtils } from '@/features/session-tracking/lib/localStorageUtils';
import { throttle } from '@/shared/lib/throttle';
import { useSessionCloser } from '@/features/session-tracking/useSessionCloser';

const SESSION_DURATION_LIMIT = 15 * 60 * 1000;
const CHECK_INTERVAL = 60 * 1000;
const activityEvents = ['mousemove', 'keydown', 'scroll', 'touchstart'];

let sessionCreating = false;

export const useSessionTracker = () => {
  const token = localStorageUtils.getToken();
  const eventHandlers = useRef<Record<string, EventListener>>({});

  const [startSession] = useMutation(startSessionMutation);
  const endCurrentSession = useSessionCloser();

  const updateLastActivity = () => {
    const now = Date.now();
    localStorageUtils.setLastActivity(now);
  };

  const handleUserActivity = useCallback(
    throttle(async () => {
      const lastActivityTime = localStorageUtils.getLastActivity() || Date.now();

      if (Date.now() - lastActivityTime > SESSION_DURATION_LIMIT) {
        await endCurrentSession();
        await createNewSession();
      } else {
        updateLastActivity();
      }
    }, CHECK_INTERVAL),
    []
  );

  const restoreSession = () => {
    const sessionId = localStorageUtils.getSessionId();
    const lastActivityTime = localStorageUtils.getLastActivity();
    const now = Date.now();

    if (sessionId && lastActivityTime && now - lastActivityTime < SESSION_DURATION_LIMIT) {
      updateLastActivity();
      addActivityListeners();
      return true;
    }
    return false;
  };

  const createNewSession = async () => {
    if (!token || sessionCreating) return;

    try {
      sessionCreating = true;
      const { data } = await startSession();

      if (data?.startSession?.success) {
        const newSessionId = data.startSession.sessionId;
        const now = Date.now();
        localStorageUtils.setSessionId(newSessionId);
        localStorageUtils.setLastActivity(now);
        addActivityListeners();
      }
    } catch (error) {
      console.error(`[${new Date().toISOString()}] Error starting session:`, error);
    } finally {
      sessionCreating = false;
    }
  };

  const addActivityListeners = () => {
    activityEvents.forEach((event) => {
      if (!eventHandlers.current[event]) {
        const uniqueHandler = (e: Event) => handleUserActivity(e);
        window.addEventListener(event, uniqueHandler);
        eventHandlers.current[event] = uniqueHandler;
      }
    });
  };

  const removeActivityListeners = () => {
    activityEvents.forEach((event) => {
      const handler = eventHandlers.current[event];
      if (handler) {
        window.removeEventListener(event, handler);
        delete eventHandlers.current[event];
      }
    });
  };

  useEffect(() => {
    const initializeSession = async () => {
      if (!token) {
        removeActivityListeners();
        return;
      }

      const restoreResult = restoreSession();
      if (!restoreResult) {
        await endCurrentSession();
        await createNewSession();
      }
    };

    initializeSession();

    return () => {
      removeActivityListeners();
    };
  }, [token]);
};
