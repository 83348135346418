import { gql } from '@apollo/client';

export const refreshTokenMutation = gql`
  mutation RefreshToken($deviceId: String!) {
    refreshToken(deviceId: $deviceId) {
      success
      message
      error {
        message
      }
      accessToken
    }
  }
`;
