import { create } from 'zustand';
import { apolloClient } from '@/shared/lib/apollo/apolloBrowser';
import { refreshTokenMutation } from './api';
import jwt from 'jsonwebtoken';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

const MIN_REFRESH_DELTA = 120000;

interface AuthState {
  accessToken: string | null;
  isAuth: boolean;
  ready: boolean;
  timeoutId: ReturnType<typeof setTimeout> | null;
  setTimeoutId: (timeout: ReturnType<typeof setTimeout> | null) => void;
  setToken: (token: string | null) => void;
  setTokenWithTimeout: (token: string) => void;
  setRefreshTimeout: (refreshAfter: number) => void;
  refreshToken: () => void;
  init: () => void;
  login: (token: string) => Promise<void>;
  logout: () => Promise<void>;
  expireCallback?: () => void;
  setExpireCallback: (callback: () => void) => void;
}

const initialState: Omit<
  AuthState,
  | 'setTimeoutId'
  | 'setToken'
  | 'setTokenWithTimeout'
  | 'setRefreshTimeout'
  | 'refreshToken'
  | 'init'
  | 'login'
  | 'logout'
  | 'setExpireCallback'
> = {
  accessToken: null,
  isAuth: false,
  ready: false,
  timeoutId: null
};

export const useAuth = create<AuthState>((set, get) => ({
  ...initialState,

  setTimeoutId: (timeoutId) => set({ timeoutId }),

  setToken: (token) => set({ accessToken: token, isAuth: Boolean(token) }),

  setRefreshTimeout: (refreshAfter) => {
    const { timeoutId, refreshToken } = get();
    if (timeoutId) clearTimeout(timeoutId);

    if (refreshAfter > 0) {
      const newTimeoutId = setTimeout(refreshToken, refreshAfter);
      set({ timeoutId: newTimeoutId });
    }
  },

  setTokenWithTimeout: (token) => {
    const { setRefreshTimeout, setToken } = get();
    const decoded = jwt.decode(token);

    if (!decoded || typeof decoded === 'string' || !decoded.exp) {
      throw new Error('Invalid token');
    }

    const exp = decoded.exp * 1000;
    const now = Date.now();
    const refreshAfter = exp - now;
    if (refreshAfter <= 0) {
      throw new Error('Token already expired');
    }

    const finalRefreshAfter = Math.max(
      refreshAfter - Math.max(MIN_REFRESH_DELTA, refreshAfter * 0.1),
      5000
    );

    setToken(token);
    setRefreshTimeout(finalRefreshAfter);
    set({ ready: true });
  },

  refreshToken: async () => {
    const { logout, setTokenWithTimeout, ready, expireCallback } = get();
    const deviceId = safeLocalStorage.getItem('deviceId');

    try {
      const { data } = await apolloClient.mutate({
        mutation: refreshTokenMutation,
        variables: { deviceId }
      });

      if (data?.refreshToken?.success) {
        setTokenWithTimeout(data.refreshToken.accessToken);
        safeLocalStorage.setItem('accessToken', data.refreshToken.accessToken);
        await fetch('/api/auth', {
          method: 'POST',
          body: JSON.stringify({ token: data.refreshToken.accessToken })
        });
      } else {
        throw new Error('Failed to refresh token');
      }
    } catch (error) {
      await logout();
      if (expireCallback) {
        expireCallback();
      }
    } finally {
      if (!ready) set({ ready: true });
    }
  },

  init: () => {
    const { setTokenWithTimeout } = get();
    const token = safeLocalStorage.getItem('accessToken');

    if (token) {
      try {
        setTokenWithTimeout(token);
      } catch {
        safeLocalStorage.removeItem('accessToken');
        set({ ready: true });
      }
    } else {
      set({ ready: true });
    }
  },

  login: async (token) => {
    await fetch('/api/auth', {
      method: 'POST',
      body: JSON.stringify({ token })
    });

    const { setTokenWithTimeout } = get();
    safeLocalStorage.setItem('accessToken', token);
    setTokenWithTimeout(token);
  },
  logout: async () => {
    await fetch('/api/delete-auth', {
      method: 'POST'
    });

    set({ ...initialState, ready: true });
    safeLocalStorage.removeItem('accessToken');
    safeLocalStorage.removeItem('userFake');
  },

  setExpireCallback: (callback) => set({ expireCallback: callback })
}));
