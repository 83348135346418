import { useQuery } from '@apollo/client';
import { checkUserAccessQuery, CheckUserAccessResponse } from './api';

export const useUserAccess = () => {
  const { data } = useQuery<CheckUserAccessResponse>(checkUserAccessQuery, {
    fetchPolicy: 'no-cache'
  });
  const allowed = data?.checkUserAccess.success ? data.checkUserAccess.allowed : true;
  const authByPhoneRestricted = data?.checkUserAccess.success
    ? data.checkUserAccess.authByPhoneRestricted
    : null;

  return {
    isBlocked: !allowed,
    authByPhoneRestricted
  };
};
