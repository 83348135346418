import { gql } from '@apollo/client';

export const startSessionMutation = gql`
  mutation StartSession {
    startSession {
      success
      sessionId
    }
  }
`;

export const endSessionMutation = gql`
  mutation EndSession($input: EndSessionRequest!) {
    endSession(input: $input) {
      success
    }
  }
`;
