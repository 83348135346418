import { useMutation } from '@apollo/client';
import { endSessionMutation } from './lib/api';
import { localStorageUtils } from '@/features/session-tracking/lib/localStorageUtils';

export const useSessionCloser = () => {
  const token = localStorageUtils.getToken();
  const [endSession] = useMutation(endSessionMutation);

  return async () => {
    const sessionId = localStorageUtils.getSessionId();

    if (!sessionId || !token) return;

    try {
      const now = Date.now();
      localStorageUtils.setLastActivity(now);
      await endSession({
        variables: { input: { sessionId, sessionEnd: now } }
      });
      localStorageUtils.clearAll();
    } catch (error) {
      console.error(`Error ending session:`, error);
    }
  };
};
