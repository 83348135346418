'use client';
import React, { useEffect } from 'react';

import { useDeviceId } from '@/entities/device-id';
import { useAuth } from '@/entities/auth';

import { useProfile } from '@/entities/profile';
import { useRouter } from 'next/navigation';
import { RoutesConfig } from '@/shared/routes/config';
import { sendLoginSuccessEvent } from '@/shared/lib/gtm/events/login-events';
import { useUserAccess } from '@/entities/blocked-ip';
import { useSessionTracker } from '@/features/session-tracking/useSessionTracking';

type Props = {
  children?: React.ReactNode;
};

const Template: React.FC<Props> = ({ children }) => {
  const { push } = useRouter();
  const { loadProfile, logout: profileLogout, profile } = useProfile();
  const { registerDevice } = useDeviceId();
  const { init, isAuth, setExpireCallback } = useAuth();
  const { isBlocked } = useUserAccess();
  useSessionTracker();

  useEffect(() => {
    registerDevice();
  }, [registerDevice]);

  useEffect(() => {
    if (isAuth && !profile) {
      loadProfile();
    }
  }, [loadProfile, isAuth, profile?.id, profile]);

  useEffect(() => {
    if (profile?.id) sendLoginSuccessEvent(profile.id);
  }, [profile?.id]);

  useEffect(() => {
    if (isBlocked) {
      push('/blocked');
    }
  }, [push, isBlocked]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setExpireCallback(() => {
      push(RoutesConfig.login);
      setTimeout(() => {
        profileLogout();
      }, 50);
    });
  }, [setExpireCallback, push, profileLogout]);

  useEffect(() => {
    if (navigator.userAgent.indexOf('Win') !== -1) {
      document.body.classList.add('custom-scrollbar');
    }

    return () => document.body.classList.remove('custom-scrollbar');
  }, []);

  return <>{children}</>;
};

export default Template;
