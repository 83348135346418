import { gql } from '@apollo/client';

export const checkUserAccessQuery = gql`
  query CheckUserAccess {
    checkUserAccess {
      success
      error {
        message
      }
      allowed
      authByPhoneRestricted
    }
  }
`;

type Success = {
  success: true;
  allowed: boolean;
  authByPhoneRestricted?: boolean;
};

type Error = {
  success: false;
  error: {
    message: string;
  };
};

export type CheckUserAccessResponse = {
  checkUserAccess: Success | Error;
};
