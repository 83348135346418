import { sendGTMEventOnce, sendGTMEventOncePerSession, sendGTMEvent } from '../events-factory';

export const sendHeaderLoginButtonEvent = () => {
  sendGTMEvent({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_header_login_button'
  });
};

export const sendLoginDontHaveAccountEvent = () => {
  sendGTMEvent({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_dont_have_account'
  });
};

export const sendLoginFormButtonSubmitEvent = (loginType: string) => {
  sendGTMEvent({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_form_button_submit',
    login_type: loginType
  });
};

export const sendLoginFailEvent = (errorText: string) => {
  sendGTMEvent({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_error',
    error_text: errorText
  });
};

export const sendLoginForgotPasswordEvent = () => {
  sendGTMEvent({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_forgot_password'
  });
};

export const sendLoginSuccessEvent = (userId: string) => {
  sendGTMEventOncePerSession({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_success',
    user_id: userId
  });
};

export const sendLoginFormInputEvent = (loginType: string) => {
  sendGTMEventOnce({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_form_field_input',
    login_type: loginType
  });
};

export const sendLoginFormPasswordInputEvent = (loginType: string) => {
  sendGTMEventOnce({
    event: 'login',
    eventCategory: 'login',
    eventAction: 'login_form_field_password',
    login_type: loginType
  });
};
