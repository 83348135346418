import { ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { showToast } from '@/shared/lib/toast';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';
import { AppConfig } from '@/shared/config';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors || networkError) {
    showToast('Something went wrong');
  }
});

export const authLink = new ApolloLink((operation, forward) => {
  if (operation.getContext().withoutAuth) return forward(operation);

  const token = safeLocalStorage.getItem('accessToken');

  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  return forward(operation);
});

export const apolloLink = ApolloLink.split(
  (op) => op.getContext().client === 'federated',
  new HttpLink({
    uri: AppConfig.FEDERATED_APOLLO_URL,
    credentials: 'include'
  }),
  new HttpLink({
    uri: AppConfig.APOLLO_URL,
    credentials: 'include'
  })
);
