export const DepositModalQuery = 'deposit';
export const WithdrawalModalQuery = 'withdrawal';

export const RoutesConfig = {
  home: '/',
  register: '?registration=true',
  login: '?login=true',
  recovery: '/recovery',
  casinoLobby: '/games',
  casinoSearch: '/games/search',
  casinoCategories: '/games/category',
  casinoKeno: '/games/category/keno',
  casinoGames: '/game',
  casinoGamesByProvider: '/games',
  profile: '/profile',
  profileEmailConfirmation: '/profile/personal-data/email-confirmation',
  profilePhoneConfirmation: '/profile/personal-data/phone-confirmation',
  profilePersonalData: '/profile/personal-data',
  responsibleGaming: '/profile/responsible-gaming',
  promo: '/promo',
  validationPhone: '/validation/phone',
  validationEmail: '/validation/email',
  withdrawals: '/profile/withdrawals',
  createWithdrawal: '/profile/withdrawals/create-withdrawal',
  transactions: '/profile/transactions',
  casinoBets: '/profile/casino-bets',
  sportBets: '/profile/sport-bets',
  personalData: '/profile/personal-data',
  deposit: `?${DepositModalQuery}`,
  depositMobile: '/deposit',
  depositSuccess: '/deposit_success',
  depositFail: '/deposit_fail',
  withdrawalModal: `?${WithdrawalModalQuery}`,
  kyc: '/kyc',
  settings: '/settings'
};
