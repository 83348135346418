/**
 * @docs
 * https://dantetech.atlassian.net/wiki/spaces/Product/pages/171245569/st+2+KYCaid#%D0%9C%D0%B0%D0%BF%D0%BF%D0%B8%D0%BD%D0%B3-%D1%81%D1%82%D0%B0%D1%82%D1%83%D1%81%D0%BE%D0%B2
 *
 * CONFIRMED - ПОДТВЕРЖДЕН
 *
 * NOT_CONFIRMED - НЕ ПОДТВЕРЖДЕН
 *
 * PROCESSING - В ОБРАБОТКЕ
 *
 * REJECTED - ОТКЛОНЕН
 *
 * EXPIRED - ИСТЕК СРОК ГОДНОСТИ
 *
 * DENIED - ОТКАЗАНО
 */
export enum KycUserStatus {
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  DENIED = 'DENIED',
}
