export const throttle = (callback: (...args: unknown[]) => void, delay: number) => {
  let last = 0;

  return (...args: unknown[]) => {
    const now = Date.now();

    if (now - last >= delay) {
      callback(...args);
      last = now;
    }
  };
};
