import { create } from 'zustand';
import { apolloClient } from '@/shared/lib/apollo/apolloBrowser';
import { Profile, ProfileData, ProfileModel } from './types';
import { getProfile } from './api';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

const initialState = {
  profile: null,
  pending: false,
  isLoggedIn: false,
  ready: false
};

export const useProfile = create<ProfileModel>()((set, get) => ({
  ...initialState,
  loadProfile: async () => {
    set({ pending: true });

    let profile: Profile | undefined;
    try {
      const response = await apolloClient.query<ProfileData>({
        query: getProfile,
        fetchPolicy: 'no-cache'
      });
      if (response.data.profile.success && response.data.profile.user) {
        set({ profile: response.data.profile.user, pending: false, isLoggedIn: true });
        profile = response.data.profile.user;

        safeLocalStorage.setItem('userFake', profile.isFake.toString());
      } else {
        console.error('Profile fetch error:', response.data.profile.error?.message);
        set({ profile: null, pending: false });
      }
    } catch (error) {
      console.error('Apollo query error:', error);
      set({ profile: null, pending: false });
    }

    set({ ready: true, pending: false });
    return profile;
  },
  logout: () => {
    set({ ...initialState, ready: true });
    safeLocalStorage.removeItem('accessToken');
    safeLocalStorage.removeItem('userFake');
  }
}));
