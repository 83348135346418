import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { apolloLink, authLink, errorLink } from './links';

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, apolloLink])
});

export const contextWithoutAuth = {
  context: {
    withoutAuth: true
  }
};
