export const AppConfig = {
  GTM_ID: process.env.GTM_ID,
  APOLLO_URL: process.env.APOLLO_URL,
  FEDERATED_APOLLO_URL: process.env.FEDERATED_APOLLO_URL,
  API_GATEWAY_URL: process.env.API_GATEWAY_URL,
  DIGITAIN_SPORTSBOOK_GUID: process.env.DIGITAIN_SPORTSBOOK_GUID,
  DIGITAIN_SPORTSBOOK_DOMAIN: process.env.DIGITAIN_SPORTSBOOK_DOMAIN,
  DIGITAIN_SPORTSBOOK_HOSTNAME: process.env.DIGITAIN_SPORTSBOOK_HOSTNAME,
  DIGITAIN_SPORTSBOOK_OPERATOR_ID: process.env.DIGITAIN_SPORTSBOOK_OPERATOR_ID,
  DIGITAIN_CASINO_OPERATOR_ID: process.env.DIGITAIN_CASINO_OPERATOR_ID,
  DIGITAIN_CASINO_API_URL: process.env.DIGITAIN_CASINO_API_URL,
  DIGITAIN_CASINO_DOMAIN: process.env.DIGITAIN_CASINO_DOMAIN,
  CONTENTMIXER_URL: process.env.CONTENTMIXER_URL
};

export const DEFAULT_CURRENCY = {
  isoCode: process.env.PAYLOAD_PUBLIC_WALLET_CURRENCY || 'USD',
  symbol: process.env.PAYLOAD_PUBLIC_WALLET_CURRENCY || '$'
};
