import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

type GTMData = {
  event: string;
  eventCategory: string;
  eventAction: string;
  [key: string]: string | number | boolean;
};

type SendEventFunction = (data: GTMData) => void;

interface EventOptions {
  once?: boolean;
  oncePerSession?: boolean;
}

const expiredKeys = new Map<string, boolean>();

const sendEvent = (data: GTMData) => {
  if (typeof window === 'undefined') return;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (window.GTM_DEBUG_MODE) {
    console.log(data);
  }

  if (window.dataLayer != null) {
    window.dataLayer.push(data);
  }
};

const createEventKey = (data: GTMData): string => {
  return Object.values(data)
    .filter((value) => typeof value === 'string')
    .join();
};

const createEventSender = (options: EventOptions): SendEventFunction => {
  return (data: GTMData) => {
    const userFake = safeLocalStorage.getItem('userFake') === 'true';

    if (userFake) {
      return;
    }

    const key = createEventKey(data);

    if (options.once) {
      if (expiredKeys.has(key)) return;
      expiredKeys.set(key, true);
    }

    if (options.oncePerSession && typeof window !== 'undefined' && window.sessionStorage) {
      if (window.sessionStorage.getItem(key)) return;
      window.sessionStorage.setItem(key, 'true');
    }

    sendEvent(data);
  };
};

export const sendGTMEventOnce = createEventSender({ once: true });
export const sendGTMEventOncePerSession = createEventSender({ oncePerSession: true });
export const sendGTMEvent = createEventSender({});
