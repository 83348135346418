'use client';
import { useEffect, useRef } from 'react';
import { KycUserStatus } from '@dante/kyc-common';
import { useProfile } from '../profile';

const MAX_RETRIES = 4;
const DELAY = 4_000;

export const useProfilePulling = () => {
  const { profile, pending, loadProfile } = useProfile();
  const retriesLeftRef = useRef(MAX_RETRIES);
  const finalizedRef = useRef(false);

  useEffect(() => {
    if (!pending && profile && !finalizedRef.current) {
      const pullProfile = async () => {
        if (finalizedRef.current) return;

        const newProfile = await loadProfile();

        if (newProfile?.kycStatus === KycUserStatus.PROCESSING) return;

        const retriesExceeded = retriesLeftRef.current <= 0;
        const rejected =
          profile?.kycStatus !== newProfile?.kycStatus &&
          newProfile?.kycStatus === KycUserStatus.REJECTED;
        const confirmed = newProfile?.kycStatus === KycUserStatus.CONFIRMED;

        if (rejected || confirmed || retriesExceeded) {
          finalizedRef.current = true;
          return;
        }

        retriesLeftRef.current -= 1;
      };

      const timeout = setTimeout(pullProfile, DELAY);

      return () => clearTimeout(timeout);
    }
  }, [pending, profile, loadProfile]);
};
