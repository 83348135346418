'use client';

import 'react-toastify/dist/ReactToastify.css';
import {
  ToastContainer as ToastContainerDefault,
  ToastContent,
  ToastOptions,
  toast
} from 'react-toastify';
import { Button, CancelIcon } from '@/shared/ui';
import React from 'react';

type ToastType = 'success' | 'error' | 'default';

export const ToastContainer = () => {
  return (
    <ToastContainerDefault
      position="bottom-right"
      autoClose={8000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      closeButton={({ closeToast }) => (
        <Button
          variant="text"
          onClick={closeToast}
          className="absolute right-2 top-2 !h-2 !w-2 !p-0 text-text-primary md:right-3 md:top-3"
        >
          <CancelIcon size={16} />
        </Button>
      )}
      toastClassName={(context) => {
        const baseClass =
          'relative overflow-hidden bottom-[60px] mt-1 max-w-[calc(100%-32px)] w-full md:max-w-[300px] my-0 mx-auto md:bottom-0 flex p-2 pr-5 md:p-3 md:pr-6 rounded-2 shadow-lg typography-mobile-small-regular md:typography-button-regular text-text-warning';
        switch (context?.type) {
          case 'success':
            return `${baseClass} bg-green-dark !text-text-success`;
          case 'error':
            return `${baseClass} bg-red-dark !text-text-error`;
          default:
            return `${baseClass} bg-yellow-darkest`;
        }
      }}
      bodyClassName="!p-0"
      progressClassName=" md:!h-[4px] !h-[2px]"
    />
  );
};

export const showToast = (
  content: ToastContent,
  type: ToastType = 'default',
  options: Partial<ToastOptions> = {}
) => {
  const mergedOptions: ToastOptions = {
    ...options
  };

  switch (type) {
    case 'success':
      return toast.success(content, mergedOptions);
    case 'error':
      return toast.error(content, mergedOptions);
    case 'default':
    default:
      return toast(content, mergedOptions);
  }
};
