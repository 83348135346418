import { create } from 'zustand';
import { apolloClient } from '@/shared/lib/apollo/apolloBrowser';
import { registerDeviceMutation } from './api';
import { v4 } from 'uuid';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

interface DeviceState {
  deviceId: string | null;
  registerDevice: () => void;
}

const initialState = {
  deviceId: null
};

export const useDeviceId = create<DeviceState>((set) => ({
  ...initialState,
  registerDevice: async () => {
    const localDeviceId = safeLocalStorage.getItem('deviceId');
    if (!localDeviceId) {
      try {
        const input = {
          deviceId: v4(),
          os: navigator.platform,
          runtime: navigator.appName,
          appName: navigator.appName,
          appVersion: navigator.appVersion,
          browser: navigator.userAgent,
          environment: navigator.language
        };

        const { data } = await apolloClient.mutate({
          mutation: registerDeviceMutation,
          variables: {
            input
          }
        });

        if (data?.registerDevice?.success) {
          safeLocalStorage.setItem('deviceId', data.registerDevice.deviceId);
          set({ deviceId: data.registerDevice.deviceId });
        } else {
          throw new Error(data);
        }
      } catch (error) {
        console.error('Error while registering deviceId', error);
      }
    } else {
      set({ deviceId: localDeviceId });
    }
  }
}));

export default useDeviceId;
