import { gql } from '@apollo/client';

export const getProfile = gql`
  query Profile {
    profile {
      success
      message
      user {
        id
        email
        phone
        emailConfirmed
        phoneConfirmed
        currencyId
        userName
        firstName
        lastName
        middleName
        gender
        birthdate
        kycStatus
        isFake
        settings {
          casinoMaxLimit
          casinoIsBlocked
          sportMaxLimit
          sportIsBlocked
        }
        balance {
          amount
          bonusAmount
          lockedAmount
          bonusLockedAmount
        }
      }
      error {
        message
        stack
      }
    }
  }
`;

export const sendResponsibleGamingRequestMutation = gql`
  mutation SendResponsibleGamingRequest($datePeriod: String!, $emailTo: String!) {
    sendResponsibleGamingRequest(datePeriod: $datePeriod, emailTo: $emailTo) {
      success
      message
      error {
        message
        stack
      }
    }
  }
`;
